import React, { useRef } from "react";
import "../../css/contact.css";




export const Prod = () => {
 
  const stl1 = {
    marginBottom:"10px",
    height:"10px"
  }
  const stl2 = {
    display:"flex",justifyContent:"center",paddingBottom:"30px"
  }
  const stl3 =
  {
    width:"80%"
  }
  const fordiv = {
    display:"flex",
    justifyContent:"center",
    backgroundColor:"#316685",
    marginBottom:"10px",
    height:"30px"
  }
  const fordiv_contain = {
    color:"white",
    fontSize:"20px"
  }

  return (
    <>
    <div style={fordiv} > <p style={fordiv_contain} >acces et participation</p></div><br/>
      <div style={stl2} >
      
      <iframe title="insert-BoulelSante" width="1200" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiYWM5YzExNWYtODExNi00MGMzLWExZDQtNjVmNDY0MjA1NjhhIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>

     

    </>
  );
};
