// Posts.js

import React from "react";
import { Post1 } from "./PostBlog";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/GestionBlog.css"
import Footer from "../Footer";
import NavBarConnexion from "../NavBarConnexion";


const GestionBlog = () => {
	return (
        <> 
        <NavBarConnexion/>
		{/*	<Container >
        <div class="col-md-3 text-center">
            </div>
			<Row className="roomfac fontReg" >
				<Col md={5} className="mb-4">
					<Post1 />
				</Col>
			</Row>
           
		</Container> */}
		<Post1 />
		<Footer/>
        </>
	);
};

export default GestionBlog;
