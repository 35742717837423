// import { Password } from '@mui/icons-material';
import React, { useState } from 'react';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({username:'', password:'' ,role:''});

  const loginUser = (userData) => {
    localStorage.setItem('role',userData.roleId)
    localStorage.setItem('username',userData.username)
    setUser(userData);
    console.log(userData);
  };

  const logoutUser = () => {
    localStorage.clear();
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
