import React from "react";
import "./FooterStyles.css";
import { Image } from "react-bootstrap";
const aphrclogo = require("./images/aphrc.png");
const ucad = require("./images/ucad.png");
const departement = require("./images/departement.png");
const mairie = require("./images/mairie.png");
const region = require("./images/region.png");
const telephone = require("./images/telephone.png");
const facebook = require("./images/facebook.png");
const instagram = require("./images/instagram.png");
const linkedIn = require("./images/linkedin.png");
const twitter = require("./images/twitter.png");
const Footer = () => {
  return (
    <>
        <div className="container-fluid footer">
    {/* <div className="contaiBox">
    <div className="box">
    
      <div className="copyright">
        <span className="linkcontact" >A propos</span>
        <ul>
          <span className="d-flex ulContainer">
            <Image
              width={40}
              height={40}
              roundedCircle
              alt="181x190"
              src={aphrclogo}
            />{" "}
            <li className="contact" >
              <a href="https://aphrc.org/" target="_blanc">APHRC(African Population and Health Research Center)</a>
            </li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={40}
              height={40}
              roundedCircle
              alt="181x190"
              src={ucad}
            />{" "}
            <li className="contact" ><a href="https://fad.igt.ucad.sn/" target="_blanc">IGT-UCAD (Institut de la Gouvernance Territoriale)</a></li>
          </span>
       
        
      
        </ul>
      </div>

      
     
      <div className="copyright">
        <span className="linkcontact" >Localisation</span>
        <ul>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
             
              alt="181x190"
              src={mairie}
            />{" "}
            <li>
           Mairie de Boulel
            </li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
             
              alt="181x190"
              src={departement}
            />{" "}
            <li>Departement de Kaffrine</li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
            
              alt="181x190"
              src={region}
            />{" "}
            <li>
              Région de Kaffrine
            </li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
              roundedCircle
              alt="181x190"
              src={telephone}
            />{" "}
            <li>
            (+221) 77 611 64 32
            </li>
          </span>
        
      
        </ul>
      </div>
  
      <div className="copyright">
        <span className="linkcontact" >Nous suivre</span>
        <ul>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
              roundedCircle
              alt="181x190"
              src={facebook}
            />{" "}
            <li className="contact" >
              <a href="www.precvadiboulel.sn" >Facebook</a>
            </li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
              roundedCircle
              alt="181x190"
              src={instagram}
            />{" "}
            <li className="contact" ><a href="www.precvadiboulel.sn" >instagram</a></li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
              roundedCircle
              alt="181x190"
              src={linkedIn}
            />{" "}
            <li className="contact" >
              <a href="www.precvadiboulel.sn"  >LinkedIn</a>
            </li>
          </span>
          <span className="d-flex ulContainer">
            <Image
              width={20}
              height={20}
              roundedCircle
              alt="181x190"
              src={twitter}
            />{" "}
            <li className="contact" >
              <a href="www.precvadiboulel.sn"  >Twitter</a>
            </li>
          </span>
        
      
        </ul>
      </div>
      </div>
      </div> */}
      <p className="lastLine fw-bold" >@Copyright in 2022 &nbsp;<span style={{color:`#208001`}}> Mairie de BOULEL</span> &nbsp;et ses partenaires ( &nbsp;<span style={{color:`#208001`}}>IGT</span> , &nbsp;<span style={{color:`#208001`}}>APHRC</span> &nbsp;).</p>
    </div>
    
    </>
  );
};
export default Footer;
