import React from "react";
import NavBarConnexion from "../NavBarConnexion";
import { PostRessource } from "./PostBlog";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/GestionBlog.css"
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../Footer";




const  GestionRessource = () => {
  return (
    <> 
        <NavBarConnexion/>
		<Container fluid >
		
		
			<Row className="roomfac fontReg">
				<Col className="w-100 mb-4">
					<PostRessource />
				</Col>
				
				
			</Row>
           
		</Container>
		<Footer />
        </>
    
    
  );
};

export default GestionRessource;

