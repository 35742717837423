import "./App.css";
import React from "react";
// import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Home from "./components/Pages/Home";
import  Actualite from "./components/Pages/Actualite";
import { Indicateur } from "./components/Pages/Indicateur";
import { Prod } from "./components/Pages/Prod";
// import { Communes } from "./components/Pages/Communes";
import { SignIn } from "./components/Pages/Signin";
import { Acteur } from "./components/Pages/Acteur";
import Acceuil from "./components/Pages/Acceuil";
import Registrer from "./components/Pages/Registrer";
import  GestionHome from "./components/PagesAdmin/GestionHome"
import GestionBlog from "./components/PagesAdmin/GestionBlog";
import GestionData from "./components/PagesAdmin/GestionData";
import GestionRessource from "./components/PagesAdmin/GestionRessources";
import GestionUser from "./components/PagesAdmin/GestionUsers";
import { UserProvider } from './UserContext';
import Ressources  from './components/Pages/Ressources';



function App() {
  
  return (
    <UserProvider> 
    <>
      <Router>
        {/* <NavBar /> */}        
        <div className="pages">
          <Switch>
            <Route exact path="/" component={Acceuil} />
            <Route path="/commune" component={Ressources} />
            {/* <Route path = "/ressources" component={Ressources}/> */}
            <Route path="/blog" component={Actualite} />
            <Route path="/signin" component={SignIn} />
            <Route path="/commune" component={Acteur} />
            <Route path="/registrer" component={Registrer} />
            <Route path="/admin" component={GestionHome} />
            <Route path="/gestionblog" component={GestionBlog} />
            <Route path="/gesdata" component={GestionData} />
            <Route path="/gesressource" component={GestionRessource} />
            <Route path="/gesuser" component={GestionUser} />
            <Route path="/indicateur" component={Indicateur} />
          </Switch>
        </div>
        
        <Route path="/production" component={Prod} />
        {/* <Footer /> */}
      </Router>
    </>
    </UserProvider>
  );
}

export default App;
