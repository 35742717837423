import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Home from "./Home";
import Footer from "../Footer";
import '../../css/Acceuil.css'
//import Newsletter from "./Components/Newsletter";

const Acceuil = () => {

  /* const [colorIndex, setColorIndex] = useState(0);
  const colors = ['red', 'blue', 'green', 'orange'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []); */

  return (
    <>
      <NavBar />
      <div className="containersearch">
        <div>
          <h5 className="igt" /* style={{ color: colors[colorIndex] }} */>
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>
      </div>

      <Home />
     {/* <Newsletter/> */}
      <Footer />
      
    </>
  );
};

export default Acceuil;
