import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../../css/NosPartenaires.css'
const aphrclogo = require("../images/aphrc.png");
const ucad = require("../images/ucad.png");
const logo = require ("../images/logo_boulel.jpg");

function Partenaires() {


  return (
    <Carousel interval={5000} pause={false} ride="carousel" controls={false} className='monstyle11'>
      <Carousel.Item>
        <div className='monstyle22'>
        <div className="monstyle331">
            <img title="aphrclogo" src={aphrclogo} alt="aphrclogo" className='monstyle44' />
            <p className="monstyle55" style={{color:`#208001`}}><a href="https://aphrc.org/" target="_blanc">APHRC</a> (African Population and Health Research Center)</p>
        </div>
        <div className="monstyle33"> 
            <img title="ucad" src={ucad} alt="ucad" className='monstyle44' />
            <p className="monstyle55" style={{color:`#208001`}}><a href="https://fad.igt.ucad.sn/" target="_blanc">IGT-UCAD</a> (Institut de la Gouvernance Territoriale)</p>
        </div>
        </div>
      </Carousel.Item>
      {/* <Carousel.Item style={monstyle2} >
        
      </Carousel.Item>
      <Carousel.Item style={monstyle2} >
        <div className="d-block w-50">
            <img style={{borderRadius:`5%`}} height="35%" width= "20%" title="BOULEL" src={logo} alt="logo"/>
        </div>
      </Carousel.Item> */}
    </Carousel>
  );
}

export default Partenaires;
