import React from "react";
import axios from "axios";
import "../../css/SignIn.css";
import { Redirect } from "react-router";
import { useState, useContext } from "react";
import Alert from "react-bootstrap/Alert";
import CryptoJS from "crypto-js";
import { UserContext } from "../../../src/UserContext";
const cacher_eyes = require("../images/cacher_eyes.png");
const afficher_eyes = require("../images/afficher_eyes.png");
export const SignIn = () => {
  const [username, setUsername] = useState("");
  const { user, loginUser } = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [mailtest, setMailtest] = useState("");
  const [passwordtype, setPasswordType] = useState("password");
  const errors = {
    uname: "connexion invalide",
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validation de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      setMailtest("Veuillez saisir une adresse e-mail valide");
    } else {
      try {
        setMailtest("");
        const response = await axios.post("https://www.precvadi.sn/login", {
          username,
          password,
        });
        console.log(response.data);
        loginUser(response.data);
        console.log(user);
        setIsSubmitted(true);
        // Handle successful login, e.g., store token in local storage or state
      } catch (error) {
        console.error(error);
        setErrorMessages({ name: "uname", message: errors.uname });
        setTimeout(() => {}, 3000);
        // Handle error, e.g., display error message to the user
      }
    }
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      //<div className="error">{errorMessages.message}</div>
      <Alert
        style={{ fontSize: "1.2rem", padding: "0.4rem" }}
        key="warning"
        variant="danger"
      >
        {errorMessages.message}
      </Alert>
    );
  //l'option voir le mot de passe
  const f_switchType = () => {
    if (passwordtype === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const renderForm = (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleLogin}>
      <h2 className="Auth-form-title">PAGE DE CONNECTION</h2>
        <div className="Auth-form-content">
          {renderErrorMessage("uname")}
          <div className="w-100 d-flex justify-content-left align-items-center">
            <label className="label"> Email</label>
          </div>
            <div className="d-flex justify-content-left align-items-center w-100 champs">
              <div className="d-flex justify-content-center align-items-center ml-0 content-icone">
                <svg className="icone" fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"><path d="M 30.933594 32.527344 C 30.785156 30.914063 30.84375 29.789063 30.84375 28.316406 C 31.574219 27.933594 32.882813 25.492188 33.101563 23.429688 C 33.675781 23.382813 34.582031 22.824219 34.847656 20.613281 C 34.988281 19.425781 34.421875 18.757813 34.074219 18.546875 C 35.007813 15.738281 36.949219 7.046875 30.488281 6.148438 C 29.820313 4.980469 28.117188 4.390625 25.90625 4.390625 C 17.050781 4.554688 15.984375 11.078125 17.925781 18.546875 C 17.578125 18.757813 17.011719 19.425781 17.152344 20.613281 C 17.421875 22.824219 18.324219 23.382813 18.898438 23.429688 C 19.117188 25.492188 20.476563 27.933594 21.210938 28.316406 C 21.210938 29.789063 21.265625 30.914063 21.117188 32.527344 C 19.367188 37.238281 7.546875 35.914063 7 45 L 45 45 C 44.453125 35.914063 32.683594 37.238281 30.933594 32.527344 Z"/></svg>
              </div>
              <input
                className="input1"
                type="text"
                name="uname"
                onChange={(e) => setUsername(e.target.value)}
                /* className="form-control mt-1" */
                placeholder="Entrer email"
                required
                style={{fontSize:`1rem`, textAlign:`start`}}
              />
            </div>
            <h6
                className="text-danger"
              >
                {mailtest}
              </h6>
            {/* <label>Password</label> */}
            <div className="w-100 d-flex justify-content-left align-items-center">
              <label className="label"> Password</label>
            </div>
          
              <div className="d-flex justify-content-center align-items-center w-100 champs">
                <div className="d-flex justify-content-center align-items-center ml-0 content-icone">
                  <svg className="icone" fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"><path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.300781 20 6 21.300781 6 23 L 6 47 C 6 48.699219 7.300781 50 9 50 L 41 50 C 42.699219 50 44 48.699219 44 47 L 44 23 C 44 21.300781 42.699219 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 25 30 C 26.699219 30 28 31.300781 28 33 C 28 33.898438 27.601563 34.6875 27 35.1875 L 27 38 C 27 39.101563 26.101563 40 25 40 C 23.898438 40 23 39.101563 23 38 L 23 35.1875 C 22.398438 34.6875 22 33.898438 22 33 C 22 31.300781 23.300781 30 25 30 Z"/></svg>
                </div>
                  <input
                    className="input1"
                    name="pass"
                    type={passwordtype}
                    onChange={(e) =>
                      setPassword(
                        CryptoJS.SHA256(e.target.value).toString(CryptoJS.enc.Hex)
                      )
                    }
                    /* className="form-control mt-1" */
                    placeholder="Entrer password"
                    required
                    style={{fontSize:`1rem`, textAlign:`start`}}
                  />
                <div
                  className="icone1"
                  onClick={f_switchType}
                >
                  <img
                    alt="voir / caché mot de passe"
                    src={
                      passwordtype === "password" ? afficher_eyes : cacher_eyes
                    }
                  />
                </div>
              </div>
            {renderErrorMessage("pass")}
          
          <div className="d-grid w-100 connect">
            <button type="submit" className="colorbtn1">
              Se connecter
            </button>
          </div>

          {/* <p className="forgot-password text-right mt-2">
          Forgot <a href="#">password?</a>
        </p> */}
        </div>
      </form>
    </div>
  );

  return (
    <>
     {/*  <NavBar /> */}
      {/* <div className="containersearch">
        <div>
          <h5 className="igt">
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>
      </div> */}
      <div className="app">
        <a href="/">
          <button className="retour">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-backspace-reverse-fill" viewBox="0 0 16 16" className="iconRetour">
              <path d="M0 3a2 2 0 0 1 2-2h7.08a2 2 0 0 1 1.519.698l4.843 5.651a1 1 0 0 1 0 1.302L10.6 14.3a2 2 0 0 1-1.52.7H2a2 2 0 0 1-2-2zm9.854 2.854a.5.5 0 0 0-.708-.708L7 7.293 4.854 5.146a.5.5 0 1 0-.708.708L6.293 8l-2.147 2.146a.5.5 0 0 0 .708.708L7 8.707l2.146 2.147a.5.5 0 0 0 .708-.708L7.707 8z"/>
            </svg>
          </button>
        </a>
        <div className="login-form">
          {isSubmitted ? <Redirect to="/admin"/> : renderForm}
        </div>
      </div>
    </>
  );
};
