
import React from 'react';
import NavBarConnexion from "../NavBarConnexion";
// import UserTable from './UserTable';
import TableUsers from './TableUsers';

const GestionUser = () => {
  // const [users, setUsers] = useState([]);
  // const [newUser, setNewUser] = useState({ username: '', password: '',  roleId:'' });

  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  // const fetchUsers = async () => {
  //   try {
  //     const response = await fetch('https://precvadi.sn/list-users'); 
  //     const data = await response.json();
  //     console.log(data);
  //     setUsers(data);
  //   } catch (error) {
  //     console.error('Error fetching users:', error);
  //   }
  // };

  // const createUser = async () => {
  //   try {
  //     const response = await fetch('https://precvadi.sn/create-user', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(newUser),
  //     });

  //     if (response.ok) {
  //       const createdUser = await response.json();
  //       setUsers([...users, createdUser]);
  //       setNewUser({ username: '', password: '',roleId:'' });
  //     } else {
  //       console.error('Create user request failed');
  //     }
  //   } catch (error) {
  //     console.error('Error creating user:', error);
  //   }
  // };

  // const updateUser = async (updatedUser) => {
  //   try {
  //     const response = await fetch(`https://precvadi.sn/update-user/${updatedUser.id}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(updatedUser),
  //     });

  //     if (response.ok) {
  //       const updatedUserData = await response.json();
  //       setUsers(users.map(user => user.id === updatedUserData.id ? updatedUserData : user));
  //     } else {
  //       console.error('Update user request failed');
  //     }
  //   } catch (error) {
  //     console.error('Error updating user:', error);
  //   }
  // };

  // const deleteUser = async (userId) => {
  //   try {
  //     const response = await fetch(`https://precvadi.sn/${userId}`, {
  //       method: 'DELETE',
  //     });

  //     if (response.ok) {
  //       setUsers(users.filter(user => user.id !== userId));
  //     } else {
  //       console.error('Delete user request failed');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting user:', error);
  //   }
  // };

  return (
    <div>
       <NavBarConnexion/>
      {/* <h1>Administration des utilisateurs </h1> */}
      {/* <form onSubmit={createUser}>
        <input
          type="text"
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
          placeholder="Name"
        />
        <input
          type="email"
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          placeholder="Email"
        />
        <button type="submit">Create</button>
      </form> */}
      <TableUsers/>
    </div>
  );

};

export default GestionUser;






 
