import React, { useEffect } from "react";
// import html2pdf from 'html2pdf.js';
import NavBarConnexion from "../NavBarConnexion";
import { Button } from "react-bootstrap";
import { useState, useRef } from "react";
import Modal from "react-modal";
import axios from "axios";
import * as XLSX from "xlsx";
import "../../css/GestionData.css";
import AjoutStructure from "./CreateStructure";
import AjoutStructureSanitaire from "./CreateStructureSanitaire";
import AjoutIndicateur from "./CreateIndicateur";
import Swal from "sweetalert2";
import Footer from "../Footer";
const educicon = require("../images/educicon.png");
const santeicon = require("../images/educsante.png");
const gif = require("../images/updload.gif");
const indicateurready = require("../images/indicateurready.gif");
const resetImage = require("../images/image_reset.png");
const modalStyles = {
  content: {
    maxHeight: "70%", // Set the maximum height of the modal content
    overflowY: "auto", // Enable vertical scrolling
    overflowX: "auto", // Enable vertical scrolling
    minHeight: "70%",
    paddingTop: "0",
  },
};
const modalStyles2 = {
  content: {
    maxHeight: "70%", // Set the maximum height of the modal content
    overflowY: "auto", // Enable vertical scrolling
    overflowX: "auto", // Enable vertical scrolling
    minHeight: "70%",
    paddingTop: "0",
  },
};

const GestionData = () => {
  const [domainechoice, setdomainechoice] = React.useState("education");
  const [isEducation, SetEducation] = React.useState(true);
  const [isSante, SetSanté] = React.useState(false);
  const HandleEducation = () => {
    SetEducation(true);
    setdomainechoice("education");
    SetSanté(false);
    console.log("test education");
  };
  const HandleSante = () => {
    SetSanté(true);
    setdomainechoice("sante");
    SetEducation(false);
    console.log("test sante ");
  };

  //----------------------------------------------Data Education ---------------------------------------------------
  const DataEducation = () => {
    useEffect(() => {
      fetchPrescolaires();
      fetchElementaire();
      fetchMoyenSecondaire();
      fetchLycee();
      fetchLycee();
      getindicateursSante();
    }, []);
    const [prescolaire, setPrescolaire] = useState([]);
    const fetchPrescolaires = async () => {
      try {
        const response = await fetch("https://precvadi.sn/list-prescolaire");
        const data = await response.json();
        console.log(data);
        setPrescolaire(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const [elementaire, setElementaire] = useState([]);
    const fetchElementaire = async () => {
      try {
        const response = await fetch("https://precvadi.sn/list-elementaire");
        const data = await response.json();
        console.log(data);
        setElementaire(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const [moyensecondaire, setMoyenSecondaire] = useState([]);
    const fetchMoyenSecondaire = async () => {
      try {
        const response = await fetch(
          "https://precvadi.sn/list-moyensecondaire"
        );
        const data = await response.json();
        console.log(data);
        setMoyenSecondaire(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const [lycee, setLycee] = useState([]);
    const fetchLycee = async () => {
      try {
        const response = await fetch("https://precvadi.sn/list-lycee");
        const data = await response.json();
        console.log(data);
        setLycee(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const options = [
      { value: "gouvernance", label: "Gouvernance" },
      { value: "accepart", label: "Acces et participation" },
      { value: "qualite", label: "Qualité" },
      { value: "envscolaire", label: "Environnement scolaire" },
      { value: "qualification", label: "Qualification" },
    ];
    const today = new Date();
    const anneactu = today.getFullYear();
    const annee = [
      { value: `${anneactu - 4}`, label: `${anneactu - 4}` },
      { value: `${anneactu - 3}`, label: `${anneactu - 3}` },
      { value: `${anneactu - 2}`, label: `${anneactu - 2}` },
      { value: `${anneactu - 1}`, label: `${anneactu - 1}` },
      { value: `${anneactu}`, label: `${anneactu}` },
    
    ];

    const echelle = [
      { value: "communale", label: "Communale" },
      { value: "structure", label: "Structure" },
    ];
    const [valueechelle, setEchelle] = React.useState("structure");
    const [subdomain, setSubdomain] = React.useState("");
    const [structure, setStructure] = React.useState("");
    const [level, setLevel] = React.useState("");
    const [year, setYear] = React.useState(`${anneactu - 1}`);
    const [indicateurs, setIndicateurs] = useState([]);
    const [indicateursSante, setIndicateursSante] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChangeEchelle = (event) => {
      setEchelle(event.target.value);
      console.log(year);
    };

    const limitSheetNameLength = (name, maxLength) => {
      if (name.length > maxLength) {
        // Si le nom dépasse la limite, le raccourcir
        return name.substring(0, maxLength);
      }
      // Sinon, retourner le nom tel quel
      return name;
    };

    const exportToExcel = () => {
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a format suitable for export
      const exportData = indicateurs.map((item) => ({
        id: item.id,
        libelle: item.libelle,
        domaine: item.domaine,
        echelle: item.echelle,
        niveauscolaire: item.niveauscolaire,
        numerateur: item.numerateur,
        valeurnumerateur: "",
        denominateur: item.denominateur,
        valeurdenominateur: "",
        // Update this line based on your data structure
      }));
      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);
      // Limit the length of the sheet name
      const limitedSheetName = limitSheetNameLength(
        `${subdomain}_${valueechelle}`,
        31
      );
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, limitedSheetName);
      // Save the workbook to a file
      XLSX.writeFile(wb, `${limitedSheetName}.xlsx`);
    };
    const exportIndicateurSanteExcel = () => {
      getindicateursSante();
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a format suitable for export
      // setIndicateursSante(indicateursSante.filter( (user)=> user.subdomain === "accepart"));
      console.log(indicateursSante);
      // indicateursSante= indicateursSante.filter (subdomain == );
      const exportData = indicateursSante.map((item) => ({
        id: item.id,
        nom_ecole: item.valeur,
        niveau: item.niveau,
        commune: "Boulel",

        // Update this line based on your data structure
      }));
      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "list_ecole");
      // Save the workbook to a file
      XLSX.writeFile(wb, `list_ecole.xlsx`);
    };

    const getindicateursSante = async () => {
      const handleIndicateurs = async () => {
        try {
          setIndicateursSante([]);
          setLoading(true);
          const response = await fetch("https://precvadi.sn/list-all-ecole");
          const data = await response.json();
          setIndicateursSante(data);
          // setIndicateursSante(data);
          console.log("response");
          console.log(data);
          console.log(indicateursSante);
        } catch (error) {
          console.error("Error fetching user:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      };
      handleIndicateurs();
    };

    const getindicateurs = (valueechelle, domain, subdomain, level) => {
      let sub_2;
      let level_2;
      if (subdomain == "Gouvernance" )
      {
        sub_2 = "gouvernance";
      }
      else if (subdomain == "Acces et participation" )
      {
        sub_2 = "accepart";
      }
      else if (subdomain == "Qualité")
      {
        sub_2 = "qualite";
      }
      else if (subdomain == "Environnement scolaire" )
      {
        sub_2 = "envscolaire";
      }
      else if (subdomain == "Qualification" )
      {
        sub_2 = "qualification";
      }
      else{
        sub_2 = subdomain;
      }
       if(level == "Moyen Secondaire"){
        level_2 = "Moyen Secondaire";
      }
      else{
        level_2 = level;
      }
   

      
      const handleIndicateurs = async () => {
        setIndicateurs([]);
        try {
          setLoading(true);
          const response = await fetch(
            `https://precvadi.sn/list-filtered-indicateurs?echelle=${valueechelle}&domain=${domain}&subdomain=${sub_2}&niveauscolaire=${level_2}`
          );
          const data = await response.json();
          setIndicateurs(data);
          console.log("response");
          console.log(data);
          console.log(indicateurs);
        } catch (error) {
          console.error("Error fetching user:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      };
      handleIndicateurs();
      console.log(subdomain);
      console.log(level);
      console.log(domain);
      console.log(valueechelle);
    };
    const showForm = () => {
      // Load your parameters here or set them dynamically
      getindicateurs(valueechelle, domainechoice, subdomain, level);
    };

    const handleChangeLevel = (event) => {
      setLevel(event.target.value);
      fetchPrescolaires();
      fetchElementaire();
      fetchMoyenSecondaire();
      fetchLycee();
    };
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenAddIndicateur, setIsOpenAddIndicateur] = useState(false);
    function toggleModal() {
      setIsOpenAdd(!isOpenAdd);
    }

    function toggleModalIndicateur() {
      setIsOpenAddIndicateur(!isOpenAddIndicateur);
    }

    const handleChange = (event) => {
      setSubdomain(event.target.value);
    };
    const handleChangeYear = (event) => {
      console.log(year);
      setYear(event.target.value);
    };
    const handleChangeStructure = (event) => {
      console.log(event.target.value);
      setStructure(event.target.value);
    };

    const [formValues, setFormValues] = useState([]);
    const [progressbar, setProgressbar] = useState("0");

    //ENVOIE UN PAR UN
    
    let delay_form = true;
    let envoireussi = true;
    let champsvide = true;
    let swal = true;
    let index = 0;
    //en cas de refus
    function tested_echec(){
      envoireussi = false;
      console.log("cool");
      index = 0;
      swal = false;
      setProgressbar("0");
      Swal.close();
      f_init();
      Swal.fire({
          title: "Echec !",
          text: "Erreur lors de l'envoi",
          icon: "error",
          cancelButtonText: "fermer",
      });
    };
    function testes_champs_vide(){
      envoireussi = false;
      console.log("cool");
      index = 0;
      swal = false;
      setProgressbar("0");
      Swal.close();
      f_init();
      Swal.fire({
          title: "Echec !",
          text: "aucun champs n'est remplis",
          icon: "error",
          cancelButtonText: "fermer",
      });
    }

    async function tested() {
      if (delay_form == true) {
          Swal.fire({
              title: "Traitement en cours...",
              text: "Veuillez ne pas fermer la page",
              html: `<img src="${indicateurready}" />`,
              background: '#F1F2F3',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
          });
          
      }
      /********************************** */
      /********************************** */
      //la comparaison chaine de caractere
       /********************************** */
      /********************************** */

      function normalizeString(str) {
        return str
          .toLowerCase() // Convertir en minuscules
          .normalize("NFD") // Séparer les caractères de base et les accents
          .replace(/[\u0300-\u036f]/g, ""); // Supprimer les accents
      }
      
      function containsString(source, search) {
        const normalizedSource = normalizeString(source);
        const normalizedSearch = normalizeString(search);
        return normalizedSource.includes(normalizedSearch);
      }
      function calculerValeur(libelle, numerateur, denominateur) {
        console.log(`${numerateur} ; ${denominateur}`);
        
        let valeur;
        if (!isNaN(numerateur)) {
          
              if (isNaN(denominateur)) {
                valeur = numerateur;
              }
              else{
                 if (containsString(libelle, "taux")) {
                  valeur = (numerateur * 1000) / denominateur;
                } else  if (containsString(libelle, "pourcentage")) {
                  valeur = (numerateur * 100) / denominateur;
                } else  if (containsString(libelle, "ratio")) {
                  valeur = numerateur / denominateur;
                }
                else if (  (containsString(libelle, "total")) || (containsString(libelle, "somme"))) {
                  valeur = numerateur + denominateur;
                }
                else{
                  valeur = numerateur / denominateur;
                }
      
              }
              return valeur;
        } else {
          return valeur;
        }
       
      /*
        if (denominateur === undefined || denominateur === null) {
          valeur = libelle;
        } else if (containsString(libelle, "taux")) {
          valeur = (numerateur * 1000) / denominateur;
        } else if (containsString(libelle, "pourcentage")) {
          valeur = (numerateur * 100) / denominateur;
        } else if (containsString(libelle, "ratio")) {
          valeur = numerateur / denominateur;
        }
       else if (((containsString(libelle, "total")) && (denominateur !== null))  || ((containsString(libelle, "total")) && (denominateur !== undefined))) {
        valeur = numerateur + denominateur;
      }
        else {
          if (denominateur !== undefined || denominateur !== null) {
            valeur = numerateur / denominateur;
          }
          valeur = numerateur;
        }*/
      
      
      
      }

      
      if (indicateurs.length !== 0 && indicateurs[index] !== undefined && envoireussi == true) {
          let headersList = {
              "Accept": "*/*",
              "Content-Type": "application/json"
          };
          const data = {
              nomecole: structure,
              niveauecole: level,
              valeurannee: year,
              valeursousdomaine: subdomain,
              valeurindicateur: `${indicateurs[index].libelle}`,
              matriculeindicateur:`${indicateurs[index].id}`,
              value_structure: calculerValeur( `${indicateurs[index].libelle}`,  parseFloat(formValues[indicateurs[index].numerateur]),  parseFloat(formValues[indicateurs[index].denominateur]))
          };
          let bodyContent = JSON.stringify(data);
          if (!isNaN(data.value_structure)  && data.value_structure != Infinity ) {
            console.log(data.value_structure);
              let url = valueechelle === "structure" ? "https://precvadi.sn/insert-structure" : "https://precvadi.sn/somme-add";
              let reqOptions = {
                  url: url,
                  method: "POST",
                  headers: headersList,
                  data: bodyContent,
              };
              try {
                  let response = await axios.request(reqOptions);
                  console.log(response);
                  delay_form = false;
                  champsvide = false;
              } catch (error) {
                  console.error("Erreur lors de la requête :", error);
                  // Si une erreur se produit, vous pouvez mettre en place une stratégie de réessai
                  console.log("ca ne reussi pas ?")
                  tested_echec();
              }
          }
          if (envoireussi == true) {
            index++;
            let sms = ((index * 100) / indicateurs.length).toString().split(".")[0] + " %";
            setProgressbar(sms);
    
            if (swal) {
                Swal.fire({
                    title: "Transfert des données en cours",
                    text: "Veuillez ne pas fermer la page",
                    html: `<img src="${indicateurready}" />`,
                    background: '#F1F2F3',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });
                swal = false;
            }
            //setTimeout(tested, 1000); // Appeler à nouveau la fonction après 1 seconde
            tested();
          }
       
         
          
      } else {
        if (indicateurs.length == 0) {
          Swal.fire({
            title: "Erreur !",
            text: "Champs vide",
            icon: "error",
            cancelButtonText: "fermer",
        });
        }
        else{
          setProgressbar("0");
          Swal.close();
          f_init();
          if (champsvide == false) {
            Swal.fire({  
              title: "Reussie !",
              text: "L'élément a été envoyé avec succés",
              icon: "success",
              cancelButtonText: "fermer",
          });
          }
          else{
            testes_champs_vide();
          }
         
        }
      }
  }
  

    const handleSubmit = async (e) => {
      e.preventDefault();
      let newData;
      if (dataload.length === 0) {
        newData = indicateurs.map((field) => ({
          idEcole: `${structure}`,
          annee: `${year}`,
          mois: "Decembre",
          CodeDomaine: 1,
          idTemps: 12,
          idIndicateur: `${field.id}`,
          // libelle: `${field.libelle}`,
          valeurs:
            parseFloat(formValues[field.numerateur]) /
              parseFloat(formValues[field.denominateur]) ||
            parseFloat(formValues[field.numerateur]),
        }));
      } else {
        newData = dataload.map((field) => ({
          idEcole: `${structure}`,
          annee: `${year}`,
          mois: "Decembre",
          CodeDomaine: 1,
          idTemps: 12,
          idIndicateur: `${field.id}`,
          // libelle: `${field.libelle}`,
          valeurs:
            parseFloat(formValues[field.numerateur]) /
              parseFloat(formValues[field.denominateur]) ||
            parseFloat(formValues[field.numerateur]),
        }));
        // Remplacer les valeurs NaN par une chaîne vide
        newData = newData.map((item) => ({
          ...item,
          valeurs: isNaN(item.valeurs) ? "" : item.valeurs,
        }));
      }
      //newData = newData.filter(item => !isNaN(item.valeurs));
      // Send the form data to the server for submission
      console.log(formValues);
      console.log("*************_-_-_-_************");
      console.log(newData);
      fileInputRef.current.value = null;

      setMode_auto(false);
      setIndicateurs([]);
      // Send the form data to the server for submission
      console.log(newData);
      try {
        console.log("structure");
        console.log(structure);
        // subdomain=`${subdomain}_${level}_${structure}_${year}`;
        console.log(subdomain);
        let annee = `${year}`;
        let mois = "Decembre";
        // const temps = new FormData();
        console.log("----Annne et mois----");
        console.log(mois);
        console.log(annee);
        /*
        const response = await axios.post("https://precvadi.sn/submitForm", {
          newData,
          subdomain,
          annee,
          mois,
          structure,
        });
        */
       


  
      tested();
        //console.log(subdomain);
        //console.log("Form data submitted successfully:");
        //f_init();
      /*  Swal.fire({
          title: "Reussie !",
          text: "L'élément a été envoyé avec succés, Voulez-vous télécharger le fichier excel ?",
          icon: "success",
          showCancelButton: true,
          cancelButtonText: "fermer",
          confirmButtonText: "Oui, télécharger le fichier",
        }).then((result) => {
          if (result.isConfirmed) {
            // After successful submission, create a download link for the CSV file
            const downloadLink = document.createElement("a");
            // const originalString = 'uploads/boulel/archive.zip'; // upload all files to integrate
          /*  const originalString = response.data.filePath;
            const substringToDelete = "/var/www/apiigt/";
            const filecsv = originalString.replace(substringToDelete, "");
            downloadLink.href = response.data.filePath;
            console.log(filecsv);
            downloadLink.href = "https://precvadi.sn/" + filecsv;
            downloadLink.download = "form_data.csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink); 
          }
        });*/
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    };
    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;
    //   console.log(e.target)
    //   setFormValues({ ...formValues, [name]: value });
    // };

    //*********************************§§************************/
    //*********************************§§************************/
    //*********************************§§************************/

    //CONTROL AVANT L ENVOIE DES DONNEES


   

    const cas_erreur = (motif) => {
      setMode_auto(false);
      Swal.fire({
        title: "Erreur !",
        text: motif,
        icon: "error",
        timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
        timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
      });
      // Réinitialiser la valeur de l'input de type fichier
      fileInputRef.current.value = null;
      return;
    };

    const [mode_auto, setMode_auto] = useState(false);
    const [dataload, setDataload] = useState([]);
    const fileInputRef = useRef(null);
    const handleFileChange = async (event) => {
      if (indicateurs.length === 0) {
        cas_erreur("Veillez d'abord choisir le formulaire à remplir");
      }
      if (indicateurs.length !== 0) {
        setMode_auto(true);
        const file = event.target.files[0];

        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Acceder au fichier excel
          const sheet = workbook.Sheets[workbook.SheetNames[0]];

          // converssion en fichier excel vers json
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          // l'ensemble des fichiers chargés
          console.log("-_-_-_-_-EXCEL DATA-_-_-_-_-_");
          console.log(jsonData);
          console.log("-_-_-_-_-FORM DATA-_-_-_-_-_");
          console.log(indicateurs);

          //filtrer que les données se trouvant dans le formulaire
          const excel_filter = indicateurs.filter(function (objet) {
            return jsonData.some(function (obj) {
              return obj.id === objet.id;
            });
          });
          if (excel_filter.length === 0) {
            cas_erreur("Ce fichier ne correspond pas au formulaire");
          }
          console.log("-_-_-_-_-filtrer_-_-_-_-_-_");
          console.log(excel_filter);

          setDataload(excel_filter);

          for (const field of jsonData) {
            await new Promise((resolve) => {
              setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [field.numerateur]: field.valeurnumerateur,
              }));
              resolve();
            });
          }
          for (const field of jsonData) {
            if (field.valeurdenominateur !== undefined) {
              await new Promise((resolve) => {
                setFormValues((prevFormValues) => ({
                  ...prevFormValues,
                  [field.denominateur]: field.valeurdenominateur,
                }));
                resolve();
              });
            }
          }

          // Additional operations can be added here if needed

          //console.log(dataload);
        };

        reader.readAsArrayBuffer(file);
      }
    };
    //Pour tout initialiser à tout moment ou aprés envoi
    const f_init = () => {
      setStructure("");
      setEchelle("structure");
      setSubdomain("");
      setLevel("");
      setYear("2023");
      setIndicateurs([]);
      setFormValues([]);
      setDataload([]);
      setMode_auto(false);
      fileInputRef.current.value = null;
    };

    return (
      <div id="printable-content">
        {progressbar !== "0" && (
        <p  style={{position:"fixed",backgroundColor:"#9f3434",marginTop:"0px",left:"0px",fontSize:"20px",top:"0px",zIndex:10000,color:"white",width:"100%",margin:"auto"}}><span>Veuillez ne pas interrompre le processus {progressbar}</span></p>
    )}
        <div className="conteneur">
          <div className="conteneur2">

            <button className="bouton4" onClick={toggleModal}>
              Gestion des Ecoles{" "}
            </button>
            <Modal
              isOpen={isOpenAdd}
              onRequestClose={toggleModal}
              contentLabel="Gestion ecole "
              className="mymodal"
              style={modalStyles}
              ariaHideApp={false}
              overlayClassName="myoverlay"
              closeTimeoutMS={500}
            >
              <AjoutStructure />
            </Modal>
            <button className="bouton4" onClick={toggleModalIndicateur}>
              Gestion des Indicateurs{" "}
            </button>
            <Modal
              isOpen={isOpenAddIndicateur}
              onRequestClose={toggleModalIndicateur}
              contentLabel="Gestion Indicateur "
              className="mymodal"
              style={modalStyles2}
              ariaHideApp={false}
              overlayClassName="myoverlay"
              closeTimeoutMS={500}
            >
              <AjoutIndicateur />
            </Modal>
          </div>
          <div className="global">
          <div className="moitie1">
            <div className="sous-moitie">
                  <select value={year} onChange={handleChangeYear} className="multiple-choix">
                    {annee.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                <select value={valueechelle} onChange={handleChangeEchelle} className="multiple-choix">
                  {echelle.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
                <select value={subdomain} onChange={handleChange} className="multiple-choix">
                  <option hidden value="">
                    Selectionnez un sous domaine
                  </option>
                  {options.map((option) => (
                    <option value={option.label}>{option.label}</option>
                  ))}
                </select>
                <select required value={level} onChange={handleChangeLevel} className="multiple-choix">
                  <option value="" hidden>
                    Selectionnez un niveau
                  </option>
                  <option value="prescolaire">Prescolaire</option>
                  <option value="elementaire">Elementaire</option>
                  <option value="moyensecondaire">Moyen Secondaire</option>
                  <option value="lycee">Lycee</option>
                </select>
                {valueechelle === "communale" ? (
                  <div></div>
                ) : (
                  <>
                    <select
                      required
                      value={structure}
                      onChange={handleChangeStructure}
                      className="multiple-choix"
                    >
                      <option value="" hidden>
                        Selectionnez une structure{" "}
                      </option>

                      {(() => {
                        switch (level) {
                          case "prescolaire":
                            return prescolaire.map((option) => (
                              <option value={option.value}>{option.valeur}</option>
                            ));
                          case "elementaire":
                            return elementaire.map((option) => (
                              <option value={option.valeur}>{option.valeur}</option>
                            ));
                          case "moyensecondaire":
                            return moyensecondaire.map((option) => (
                              <option value={option.valeur}>{option.valeur}</option>
                            ));
                          case "lycee":
                            return lycee.map((option) => (
                              <option value={option.valeur}>{option.valeur}</option>
                            ));
                          default:
                            return lycee.map((option) => (
                              <option value={option.valeur}>{option.valeur}</option>
                            ));
                        }
                      })()}
                    </select>
                  </>
                )} 
                </div>             
            <div className="w-100 moitie2">
              <div className="w-50 d-flex justify-content-center align-items-center">
                <button className="bouton-choix" onClick={showForm}>
                    Afficher formulaire{" "}
                  </button>
              </div>
              <div className="w-50 d-flex justify-content-center align-items-center">
                <button className="bouton-choix" onClick={exportIndicateurSanteExcel}>
                    Telecharger Indicateur education{" "}
                  </button>
              </div>   
                  
              </div>
            
            
            
            
            

            {/* <select value={structure} onChange={handleChangeStructure}>  
      {educationboulel.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
      </select> */}

            {/* <button style={{backgroundColor:"#174751" }} onClick={generatePDF}>Generer en PDF </button> */}
            
            
          </div>
          <div className="div-reset">
          <img
            onClick={f_init}
            src={resetImage}
            title="click pour initialiser"
            alt="bouton pour rmettre à l'etat initial"
            className="reset"
          >
          </img>
          </div>
          </div>
          <div className="maindata">
            <div className="datatoadd">
              {loading && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <img src={gif} alt="Loading" width="50" height="50" />
                </div>
              )}
              {/* formulaire  */}
              {/* formulaire  */}
              {!mode_auto && (
                <form onSubmit={handleSubmit}>
                  {indicateurs.length !== 0 && ( <> 
                    <p style={{textAlign:"center"}} >Veuillez remplacer les virgule par des points ex : <span style={{backgroundColor:"#2bb977",color:"white"}} >23.44</span> et non <span style={{backgroundColor:"#c27272",color:"white"}} >23,44</span></p></> )} <br />
                  {/* <h5 style={{alignSelf:'center'}}> {subdomain}</h5> */}
                  {indicateurs.map((field) => (
                    <div>
                      <p style={{textAlign:"center", backgroundColor:"#0080003b",width:"100%", fontSize:"20px", color:"#258BAF"}}>Identifiant : {field.id}</p>
                      <div key={field.libelle} id="form-group">
                        <label
                          style={{ color: "#258BAF", textAlign:"right", fontSize:"16px" }}
                          htmlFor={field.libelle}
                        >
                          {field.libelle}
                        </label>
                      </div>
                      <div key={field.numerateur} id="form-group">
                        <label style={{fontSize:"16px"}} htmlFor={field.numerateur}>
                          {field.numerateur}
                        </label>
                        <input
                          type="text"
                          id={field.numerateur}
                          name={field.numerateur}
                          value={formValues[field.numerateur]}
                          // onChange={handleInputChange}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [field.numerateur]: e.target.value,
                            })
                          }
                        />
                      </div>
                      {field.denominateur === "" ? (
                        ""
                      ) : (
                        <div  key={field.denominateur} id="form-group">
                          <label style={{fontSize:"16px"}} htmlFor={field.denominateur}>
                            {field.denominateur}
                          </label>
                          <input
                            type="text"
                            id={field.denominateur}
                            name={field.denominateur}
                            value={formValues[field.denominateur]}
                            // onChange={handleInputChange}

                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [field.denominateur]: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  {indicateurs.length !== 0 && ( <> 
                  <button style={{ marginRight: 20, backgroundColor: "red" }} color="red" type="reset" > Annuler </button> 
                  <button type="submit">Envoyer</button> </> )} <br />
                  {/* <button
                    style={{ marginRight: 20, backgroundColor: "red" }}
                    color="red"
                    type="reset"
                  >
                    Annuler
                  </button>
                  <button type="submit" >Envoyer</button> <br /> */}
                </form>
              )}

              {mode_auto && (
                <form onSubmit={handleSubmit}>
                  {indicateurs.length !== 0 && ( <> 
                    <p style={{textAlign:"center"}} >Veuillez remplacer les virgule par des points ex : <span style={{backgroundColor:"#2bb977",color:"white"}} >23.44</span> et non <span style={{backgroundColor:"#c27272",color:"white"}} >23,44</span></p></> )} <br />
                  {/* <h5 style={{alignSelf:'center'}}> {subdomain}</h5> */}
                  {dataload.map((field) => (
                    <div>
                       <p style={{textAlign:"center", backgroundColor:"#0080003b",width:"100%", fontSize:"20px", color:"#258BAF"}}>Identifiant : {field.id}</p>
                      <div key={field.libelle} id="form-group">
                        <label
                                style={{ color: "#258BAF", textAlign:"right", fontSize:"16px" }}
                          htmlFor={field.libelle}
                        >
                          {field.libelle}
                        </label>
                      </div>
                      <div key={field.numerateur} id="form-group">
                        <label style={{fontSize:"16px"}} htmlFor={field.numerateur}>
                          {field.numerateur}
                        </label>
                        <input
                          type="text"
                          id={field.numerateur}
                          name={field.numerateur}
                          value={formValues[field.numerateur]}
                          // onChange={handleInputChange}
                        />
                      </div>
                      {field.denominateur === undefined ||
                      field.denominateur === "" ? (
                        ""
                      ) : (
                        <div key={field.denominateur} id="form-group">
                          <label style={{fontSize:"16px"}} htmlFor={field.denominateur}>
                            {field.denominateur}
                          </label>
                          <input
                            type="text"
                            id={field.denominateur}
                            name={field.denominateur}
                            value={formValues[field.denominateur]}
                            // onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  {indicateurs.length !== 0 && ( <> 
                  <button style={{ marginRight: 20, backgroundColor: "red" }} color="red" type="reset" > Annuler </button> 
                  <button type="submit">Envoyer</button> </> )} <br />
                  {/* <button
                    style={{ marginRight: 20, backgroundColor: "red" }}
                    color="red"
                    type="reset"
                  >
                    Annuler
                  </button>
                  <button type="submit" >Envoyer</button> */}
                </form>
              )}

              

              {/* <FormComponent subdomain={subdomain} year={year} structure={structure} niveau = {level} /> */}
            </div>
          </div>
          <input
                type="file"
                className="charger_"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <br />
              <div className="d-flex justify-content-start align-items-end gap-3">
                <button
                  className="telecharger"
                  onClick={exportToExcel}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16" style={{color:"white"}}>
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                  </svg>
                  
                </button>
                <p style={{marginTop:"0.5rem", fontSize:"0.8rem"}}>Telecharger Fichier Excel{" "}</p>
              </div>
        </div>
      </div>
    );
  };

  //----------------------------------------------Data Sante ---------------------------------------------------

  const DataSante = () => {
    useEffect(() => {
      fetchPoste();
      fetchPharmacie();
      fetchHopital();
      getindicateursSante();
    }, []);

    const [poste, setPoste] = useState([]);
    const fetchPoste = async () => {
      try {
        const response = await fetch("https://precvadi.sn/list-poste");
        const data = await response.json();
        console.log(data);
        setPoste(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const [hopital, setHopital] = useState([]);
    const fetchHopital = async () => {
      try {
        const response = await fetch("https://precvadi.sn/list-hopital");
        const data = await response.json();
        console.log(data);
        setHopital(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const [pharmacie, setPharmacie] = useState([]);
    const fetchPharmacie = async () => {
      try {
        const response = await fetch("https://precvadi.sn/list-pharmacie");
        const data = await response.json();
        console.log(data);
        setPharmacie(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const listemois = [
      { value: "janvier", label: "Janvier" },
      { value: "fevrier", label: "Février" },
      { value: "mars", label: "Mars" },
      { value: "avril", label: "Avril" },
      { value: "mai", label: "Mai" },
      { value: "juin", label: "Juin" },
      { value: "juillet", label: "Juillet" },
      { value: "aout", label: "Août" },
      { value: "septembre", label: "Septembre" },
      { value: "octobre", label: "Octobre" },
      { value: "novembre", label: "Novembre" },
      { value: "decembre", label: "Décembre" },
    ];
    const today = new Date();
    const anneactu = today.getFullYear();
    const annees = [
           { value: `${anneactu - 4}`, label: `${anneactu - 4}` },
      { value: `${anneactu - 3}`, label: `${anneactu - 3}` },
      { value: `${anneactu - 2}`, label: `${anneactu - 2}` },
      { value: `${anneactu - 1}`, label: `${anneactu - 1}` },
      { value: `${anneactu}`, label: `${anneactu}` },
    ];
    const echelle = [
      { value: "communale", label: "communale" },
      { value: "structure", label: "structure" },
    ];

    const options = [
      { value: "gouvesante", label: "Gouvernance Santé" },
      { value: "depensesante", label: "Depenses Santé" },
      { value: "accessgeographique", label: "Accessibilité géographique" },
      { value: "ressourceshumaines", label: "Ressources Humaines" },
      {
        value: "santedelamere-dunouveau",
        label: "Santé de la mere et du nouveau",
      },
      { value: "mortalite", label: "Mortalité" },
      { value: "planificationfamiliale", label: "Planification Familiale" },
      { value: "vaccinationderoutinePEV", label: "Vaccination de routine PEV" },
      { value: "surviedelenfant", label: "Survie de lEnfant" },
      { value: "alimentationnutrition", label: "Alimentation et nutrition" },
      {
        value: "maladiesnontransmissible",
        label: "Maladies non transmissibles",
      },
      {
        value: "maladiestropicalesneglige",
        label: "MTN Maladies Tropicales Négligés",
      },
      { value: "tuberculose", label: "Tuberculose" },
      { value: "lepre", label: "Lèpre" },
      { value: "hepatite", label: "Hépatite" },
      { value: "vihsidaist", label: "VIH SIDA IST" },
      { value: "paludisme", label: "Paludisme" },
      {
        value: "disponibiliteservicesdebase",
        label: "Disponibilité services de base",
      },
      { value: "actionsociale", label: "Action Sociale" },
      { value: "wash", label: "WASH" },
      { value: "ansd", label: "ANSD données attendues" },
    ];
    const [subdomain, setSubdomain] = React.useState("");
    const [valueechelle, setEchelle] = React.useState("structure");
    const [structure, setStructure] = React.useState("");
    const [mois, setMois] = React.useState("janvier");
    const [level, setLevel] = React.useState("");
    const [year, setYear] = React.useState(`${anneactu - 1}`);
    const [indicateurs, setIndicateurs] = useState([]);
    const [indicateursSante, setIndicateursSante] = useState([]);
    const [loading, setLoading] = useState(false);

    // const generatePDF = () => {
    //   const printableContent = document.getElementById('printable-content');
    //   html2pdf().from(printableContent).save('formulaireremplissageprecvadi.pdf');
    // };

    const limitSheetNameLength = (name, maxLength) => {
      if (name.length > maxLength) {
        // Si le nom dépasse la limite, le raccourcir
        return name.substring(0, maxLength);
      }
      // Sinon, retourner le nom tel quel
      return name;
    };

    const exportToExcel = () => {
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a format suitable for export
      const exportData = indicateurs.map((item) => ({
        id: item.id,
        libelle: item.libelle,
        domaine: item.domaine,
        echelle: item.echelle,
        niveauscolaire: item.niveauscolaire,
        numerateur: item.numerateur,
        valeurnumerateur: "",
        denominateur: item.denominateur,
        valeurdenominateur: "",
        // Update this line based on your data structure
      }));
      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);
      // Limit the length of the sheet name
      const limitedSheetName = limitSheetNameLength(
        `${subdomain}_${valueechelle}`,
        31
      );
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, limitedSheetName);
      // Save the workbook to a file
      XLSX.writeFile(wb, `${limitedSheetName}.xlsx`);
    };

    const getindicateurs = (valueechelle, domain, subdomain, level) => {
      let sub_2;
      if (subdomain == "Gouvernance Santé") {
        sub_2 = "gouvesante";
    } else if (subdomain == "Depenses Santé") {
        sub_2 = "depensesante";
    } else if (subdomain == "Accessibilité géographique") {
        sub_2 = "accessgeographique";
    } else if (subdomain == "Ressources Humaines") {
        sub_2 = "ressourceshumaines";
    } else if (subdomain == "Santé de la mere et du nouveau") {
        sub_2 = "santedelamere-dunouveau";
    } else if (subdomain == "Mortalité") {
        sub_2 = "mortalite";
    } else if (subdomain == "Planification Familiale") {
        sub_2 = "planificationfamiliale";
    } else if (subdomain == "Vaccination de routine PEV") {
        sub_2 = "vaccinationderoutinePEV";
    } else if (subdomain == "Survie de lEnfant") {
        sub_2 = "surviedelenfant";
    } else if (subdomain == "Alimentation et nutrition") {
        sub_2 = "alimentationnutrition";
    } else if (subdomain == "Maladies non transmissibles") {
        sub_2 = "maladiesnontransmissible";
    } else if (subdomain == "MTN Maladies Tropicales Négligés") {
        sub_2 = "maladiestropicalesneglige";
    } else if (subdomain == "Tuberculose") {
        sub_2 = "tuberculose";
    } else if (subdomain == "Lèpre") {
        sub_2 = "lepre";
    } else if (subdomain == "Hépatite") {
        sub_2 = "hepatite";
    } else if (subdomain == "VIH SIDA IST") {
        sub_2 = "vihsidaist";
    } else if (subdomain == "Paludisme") {
        sub_2 = "paludisme";
    } else if (subdomain == "Disponibilité services de base") {
        sub_2 = "disponibiliteservicesdebase";
    } else if (subdomain == "Action Sociale") {
        sub_2 = "actionsociale";
    } else if (subdomain == "WASH") {
        sub_2 = "wash";
    } else if (subdomain == "ASND données attendues") {
        sub_2 = "ansd";
    }
    else{
      sub_2 = subdomain;
    }
    
      const handleIndicateurs = async () => {
        setIndicateurs([]);
        try {
          setLoading(true);
          const response = await fetch(
            `https://precvadi.sn/list-filtered-indicateurs?echelle=${valueechelle}&domain=${domain}&subdomain=${sub_2}&niveauscolaire=${level}`
          );
          const data = await response.json();
          setIndicateurs(data);
          console.log("response");
          console.log(data);
          console.log(indicateurs);
        } catch (error) {
          console.error("Error fetching user:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      };
      handleIndicateurs();
      console.log(subdomain);
      console.log(level);
      console.log(domain);
      console.log(valueechelle);
    };
    const exportIndicateurSanteExcel = () => {
      getindicateursSante();
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a format suitable for export
      const exportData = indicateursSante.map((item) => ({
        id: item.id,
        libelle: item.libelle,

        // Update this line based on your data structure
      }));
      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "listindicateursante");
      // Save the workbook to a file
      XLSX.writeFile(wb, `listindicateurssante.xlsx`);
    };

    const getindicateursSante = () => {
      const handleIndicateurs = async () => {
        setIndicateursSante([]);
        try {
          setLoading(true);
          const response = await fetch(
            "https://precvadi.sn/list-all-indicateur-sante"
          );
          const data = await response.json();
          setIndicateursSante(data);
          console.log("response");
          console.log(data);
          console.log(indicateursSante);
        } catch (error) {
          console.error("Error fetching user:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      };
      handleIndicateurs();
    };
    const showForm = () => {
      //ad your parameters here or set them dynamically
      getindicateurs(valueechelle, domainechoice, subdomain, level);
      console.log(indicateurs.count);
    };

    const handleChangeLevel = (event) => {
      setLevel(event.target.value);
      fetchHopital();
      fetchPoste();
      fetchPharmacie();
    };
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    function toggleModal() {
      setIsOpenAdd(!isOpenAdd);
    }
    const [isOpenAddIndicateur, setIsOpenAddIndicateur] = useState(false);

    function toggleModalIndicateur() {
      setIsOpenAddIndicateur(!isOpenAddIndicateur);
    }

    const handleChangeStructure = (event) => {
      setStructure(event.target.value);
    };
    const handleChangeEchelle = (event) => {
      setEchelle(event.target.value);
    };
    const handleChangeYear = (event) => {
      console.log(year);
      setYear(event.target.value);
    };

    const handleChange = (event) => {
      setSubdomain(event.target.value);
    };

    const handleMois = (event) => {
      setMois(event.target.value);
      
    };
    const [formValues, setFormValues] = useState([]);



    const [progressbar, setProgressbar] = useState("0");

    //ENVOIE UN PAR UN
    
    let delay_form = true;
    let envoireussi = true;
    let champsvide = true;
    let swal = true;
    let index = 0;
    //en cas de refus
    function tested_echec(){
      envoireussi = false;
      console.log("cool");
      index = 0;
      swal = false;
      setProgressbar("0");
      Swal.close();
      f_init();
      Swal.fire({
          title: "Echec !",
          text: "Erreur lors de l'envoi",
          icon: "error",
          cancelButtonText: "fermer",
      });
    };
    function testes_champs_vide(){
      envoireussi = false;
      console.log("cool");
      index = 0;
      swal = false;
      setProgressbar("0");
      Swal.close();
      f_init();
      Swal.fire({
          title: "Echec !",
          text: "aucun champs n'est remplis",
          icon: "error",
          cancelButtonText: "fermer",
      });
    }

    async function tested() {
      
      if (delay_form == true) {
          Swal.fire({
              title: "Traitement en cours...",
              text: "Veuillez ne pas fermer la page",
              html: `<img src="${indicateurready}" />`,
              background: '#F1F2F3',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
          });
          
      }


      if (indicateurs.length !== 0 && indicateurs[index] !== undefined && envoireussi == true) {
      
          let headersList = {
              "Accept": "*/*",
              "Content-Type": "application/json"
          };
          const data = {
              nomdistrict_sante: structure,
              niveaudistrict_sante: level,
              valeurannee_sante: mois + " " + year,
              valeursousdomaine_sante: subdomain,
              valeurindicateur_sante: `${indicateurs[index].libelle}`,
              matriculeindicateur_sante: `${indicateurs[index].id}`,
              value_structure_sante:  calculerValeur( `${indicateurs[index].libelle}`,  parseFloat(formValues[indicateurs[index].numerateur]),  parseFloat(formValues[indicateurs[index].denominateur]))
          };
          console.log(data);
          let bodyContent = JSON.stringify(data);
          if (!isNaN(data.value_structure_sante)  && data.value_structure_sante != Infinity ) {
            let url = valueechelle === "structure" ? "https://precvadi.sn/insert-sante" : "https://precvadi.sn/somme-add-sante";
              let reqOptions = {
                  url: url,
                  method: "POST",
                  headers: headersList,
                  data: bodyContent,
              };
              try {
                  let response = await axios.request(reqOptions);
                  console.log(response);
                  delay_form = false;
                  champsvide = false;
              } catch (error) {
                  console.error("Erreur lors de la requête :", error);
                  // Si une erreur se produit, vous pouvez mettre en place une stratégie de réessai
                  console.log("ca ne reussi pas ?")
                  tested_echec();
              }
          }
          if (envoireussi == true) {
            index++;
            let sms = ((index * 100) / indicateurs.length).toString().split(".")[0] + " %";
            setProgressbar(sms);
    
            if (swal) {
                Swal.fire({
                    title: "Transfert des données en cours",
                    text: "Veuillez ne pas fermer la page",
                    html: `<img src="${indicateurready}" />`,
                    background: '#F1F2F3',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });
                swal = false;
            }
            //setTimeout(tested, 1000); // Appeler à nouveau la fonction après 1 seconde
            tested();
          }
       
         
          
      } else {
        if (indicateurs.length == 0) {
          Swal.fire({
            title: "Erreur !",
            text: "Champs vide",
            icon: "error",
            cancelButtonText: "fermer",
        });
        }
        else{
          setProgressbar("0");
          Swal.close();
          f_init();
          if (champsvide == false) {
            Swal.fire({  
              title: "Reussie !",
              text: "L'élément a été envoyé avec succés",
              icon: "success",
              cancelButtonText: "fermer",
          });
          }
          else{
            testes_champs_vide();
          }
         
        }
      }
  }




       /********************************** */
      /********************************** */
      //la comparaison chaine de caractere
       /********************************** */
      /********************************** */

      function normalizeString(str) {
        return str
          .toLowerCase() // Convertir en minuscules
          .normalize("NFD") // Séparer les caractères de base et les accents
          .replace(/[\u0300-\u036f]/g, ""); // Supprimer les accents
      }
      
      function containsString(source, search) {
        const normalizedSource = normalizeString(source);
        const normalizedSearch = normalizeString(search);
        return normalizedSource.includes(normalizedSearch);
      }
      function calculerValeur(libelle, numerateur, denominateur) {
        console.log(`${numerateur} ; ${denominateur}`);
        
        let valeur;
        if (!isNaN(numerateur)) {
          
              if (isNaN(denominateur)) {
                valeur = numerateur;
              }
              else{
                 if (containsString(libelle, "taux")) {
                  valeur = (numerateur * 1000) / denominateur;
                } else  if (containsString(libelle, "pourcentage")) {
                  valeur = (numerateur * 100) / denominateur;
                } else  if (containsString(libelle, "ratio")) {
                  valeur = numerateur / denominateur;
                }
                else if (  (containsString(libelle, "total")) || (containsString(libelle, "somme"))) {
                  valeur = numerateur + denominateur;
                }
                else{
                  valeur = numerateur / denominateur;
                }
      
              }
              return valeur;
        } else {
          return valeur;
        }
       
      /*
        if (denominateur === undefined || denominateur === null) {
          valeur = libelle;
        } else if (containsString(libelle, "taux")) {
          valeur = (numerateur * 1000) / denominateur;
        } else if (containsString(libelle, "pourcentage")) {
          valeur = (numerateur * 100) / denominateur;
        } else if (containsString(libelle, "ratio")) {
          valeur = numerateur / denominateur;
        }
       else if (((containsString(libelle, "total")) && (denominateur !== null))  || ((containsString(libelle, "total")) && (denominateur !== undefined))) {
        valeur = numerateur + denominateur;
      }
        else {
          if (denominateur !== undefined || denominateur !== null) {
            valeur = numerateur / denominateur;
          }
          valeur = numerateur;
        }*/
      
      
      
      }
     


    const handleSubmit = async (e) => {
      e.preventDefault();
      const newData = indicateurs.map((field) => ({
        idEcole: `${structure}`,
        CodeDomaine: 2,
        annee: `${year}`,
        mois: `${mois}`,
        idTemps: 12,
        idIndicateur: `${field.id}`,
        
        // libelle: `${field.libelle}`,
        
        valeurs: calculerValeur( `${indicateurs[index].libelle}`,  parseFloat(formValues[indicateurs[index].numerateur]),  parseFloat(formValues[indicateurs[index].denominateur]))
      }));

      // Send the form data to the server for submission
      console.log(newData);
      try {
        console.log("structure");
        console.log(structure);
        // subdomain=`${subdomain}_${level}_${structure}_${year}`;
        console.log(subdomain);
        let annee = `${year}`;
/*
        const response = await axios.post("https://precvadi.sn/submitForm", {
          newData,
          subdomain,
          annee,
          mois,
        });
        console.log(subdomain);
        console.log("Form data submitted successfully:");
        f_init();
        Swal.fire({
          title: "Reussie !",
          text: "L'élément a été envoyé avec succés, Voulez-vous télécharger le fichier excel ?",
          icon: "success",
          showCancelButton: true,
          cancelButtonText: "fermer",
          confirmButtonText: "Oui, télécharger le fichier",
        }).then((result) => {
          if (result.isConfirmed) {
            // After successful submission, create a download link for the CSV file
            const downloadLink = document.createElement("a");
            // const originalString = 'uploads/boulel/archive.zip'; // upload all files to integrate
            const originalString = response.data.filePath;
            const substringToDelete = "/var/www/apiigt/";
            const filecsv = originalString.replace(substringToDelete, "");
            downloadLink.href = response.data.filePath;
            console.log(filecsv);
            downloadLink.href = "https://precvadi.sn/" + filecsv;
            downloadLink.download = "form_data.csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        });*/
        tested();
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    };
    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;
    //   console.log(e.target)
    //   setFormValues({ ...formValues, [name]: value });
    // };

    //*********************************§§************************/
    //*********************************§§************************/
    //*********************************§§************************/

    //CONTROL AVANT L ENVOIE DES DONNEES

    const cas_erreur = (motif) => {
      setMode_auto(false);
      Swal.fire({
        title: "Erreur !",
        text: motif,
        icon: "error",
        timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
        timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
      });
      // Réinitialiser la valeur de l'input de type fichier
      fileInputRef.current.value = null;
      return;
    };

    const [mode_auto, setMode_auto] = useState(false);
    const [dataload, setDataload] = useState([]);
    const fileInputRef = useRef(null);
    const handleFileChange = async (event) => {
      if (indicateurs.length === 0) {
        cas_erreur("Veillez d'abord choisir le formulaire à remplir");
      }
      if (indicateurs.length !== 0) {
        setMode_auto(true);
        const file = event.target.files[0];

        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Acceder au fichier excel
          const sheet = workbook.Sheets[workbook.SheetNames[0]];

          // converssion en fichier excel vers json
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          // l'ensemble des fichiers chargés
          console.log("-_-_-_-_-EXCEL DATA-_-_-_-_-_");
          console.log(jsonData);
          console.log("-_-_-_-_-FORM DATA-_-_-_-_-_");
          console.log(indicateurs);

          //filtrer que les données se trouvant dans le formulaire
          const excel_filter = indicateurs.filter(function (objet) {
            return jsonData.some(function (obj) {
              return obj.id === objet.id;
            });
          });
          if (excel_filter.length === 0) {
            cas_erreur("Ce fichier ne correspond pas au formulaire");
          }
          console.log("-_-_-_-_-filtrer_-_-_-_-_-_");
          console.log(excel_filter);

          setDataload(excel_filter);

          for (const field of jsonData) {
            await new Promise((resolve) => {
              setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [field.numerateur]: field.valeurnumerateur,
              }));
              resolve();
            });
          }
          for (const field of jsonData) {
            if (field.valeurdenominateur !== undefined) {
              await new Promise((resolve) => {
                setFormValues((prevFormValues) => ({
                  ...prevFormValues,
                  [field.denominateur]: field.valeurdenominateur,
                }));
                resolve();
              });
            }
          }

          // Additional operations can be added here if needed

          //console.log(dataload);
        };

        reader.readAsArrayBuffer(file);
      }
    };

    //Pour tout initialiser à tout moment ou aprés envoi
    const f_init = () => {
      setStructure("");
      setEchelle("structure");
      setSubdomain("");
      setMois("janvier");
      setLevel("");
      setYear("2023");
      setIndicateurs([]);
      setFormValues([]);
      setDataload([]);
      setMode_auto(false);
      fileInputRef.current.value = null;
    };

    return (
      <div id="printable-content">
        {progressbar !== "0" && (
        <p  style={{position:"fixed",backgroundColor:"#9f3434",marginTop:"0px",left:"0px",fontSize:"20px",top:"0px",zIndex:10000,color:"white",width:"100%",margin:"auto"}}><span>Veuillez ne pas interrompre le processus {progressbar}</span></p>
    )}
        <div className="conteneur">
          <div className="conteneur2">

            <button className="bouton4" onClick={toggleModal}>
              Gestion des Structures de Santé{" "}
            </button>
            <Modal
              isOpen={isOpenAdd}
              onRequestClose={toggleModal}
              contentLabel="Gestion ecole "
              className="mymodal"
              style={modalStyles}
              ariaHideApp={false}
              overlayClassName="myoverlay"
              closeTimeoutMS={500}
            >
              <AjoutStructureSanitaire />
            </Modal>
            <button
              className="bouton4"
              onClick={toggleModalIndicateur}
            >
              Gestion des Indicateurs{" "}
            </button>
            <Modal
              isOpen={isOpenAddIndicateur}
              onRequestClose={toggleModalIndicateur}
              contentLabel="Gestion Indicateur "
              className="mymodal"
              style={modalStyles2}
              ariaHideApp={false}
              overlayClassName="myoverlay"
              closeTimeoutMS={500}
            >
              <AjoutIndicateur />
            </Modal>
          </div>
          <div className="global">
            <div className="moitie1">
              <div className="sous-moitie">
                <select value={mois} onChange={handleMois} className="multiple-choix">
                  {listemois.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
                <select value={year} onChange={handleChangeYear} className="multiple-choix">
                  {annees.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
                <select value={valueechelle} onChange={handleChangeEchelle} className="multiple-choix">
                  {echelle.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
                <select value={subdomain} onChange={handleChange} className="multiple-choix">
                  <option hidden value="">
                    Selectionnez un sous domaine
                  </option>
                  {options.map((option) => (
                    <option value={option.label}>{option.label}</option>
                  ))}
                </select>

                <select required value={level} onChange={handleChangeLevel} className="multiple-choix">
                  <option value="" hidden>
                    Selectionnez un niveau
                  </option>
                  <option value="poste">Poste de Sante</option>
                  <option value="hopital">Hopital</option>
                  <option value="pharmacie">Pharmacie</option>
                </select>
                {valueechelle === "communale" ? (
                  <div></div>
                ) : (
                  <>
                    <select value={structure} onChange={handleChangeStructure} className="multiple-choix">
                      <option value="" hidden>
                        Selectionnez une structure{" "}
                      </option>

                      {(() => {
                        switch (level) {
                          case "poste":
                            return poste.map((option) => (
                              <option value={option.value} key={option.id}>
                                {option.valeur}
                              </option>
                            ));
                          case "hopital":
                            return hopital.map((option) => (
                              <option value={option.value} key={option.id}>
                                {option.valeur}
                              </option>
                            ));
                          case "pharmacie":
                            return pharmacie.map((option) => (
                              <option value={option.value} key={option.id}>
                                {option.valeur}
                              </option>
                            ));
                          default:
                            return poste.map((option) => (
                              <option value={option.value} key={option.id}>
                                {option.valeur}
                              </option>
                            ));
                        }
                      })()}
                    </select>
                  </>
                )}
          </div>

          <div className="w-100 moitie2">
              <div className="w-50 d-flex justify-content-center align-items-center">
                <button className="bouton-choix" onClick={showForm}>
                    Afficher formulaire{" "}
                  </button>
              </div>
              <div className="w-50 d-flex justify-content-center align-items-center">
                <button className="bouton-choix" onClick={exportIndicateurSanteExcel}>
                    Telecharger Indicateur Sante{" "}
                  </button>
              </div>   
                  
          </div>
          </div>
          <div className="div-reset">
            <img
              onClick={f_init}
              src={resetImage}
              title="click pour initialiser"
              alt="bouton pour rmettre à l'etat initial"
              className="reset"
            >
            </img>
          </div>
          </div>
          <div className="maindata">
            <div className="datatoadd">
              {loading && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <img src={gif} alt="Loading" width="50" height="50" />
                </div>
              )}
              {/* <FormComponentSante subdomain={subdomain}  year={year} structure={structure} /> */}
              {!mode_auto && (
                <form onSubmit={handleSubmit}>
                  {indicateurs.length !== 0 && ( <> 
                    <p style={{textAlign:"center"}} >Veuillez remplacer les virgule par des points ex : <span style={{backgroundColor:"#2bb977",color:"white"}} >23.44</span> et non <span style={{backgroundColor:"#c27272",color:"white"}} >23,44</span></p></> )} <br />
                  {/* <h5 style={{alignSelf:'center'}}> {subdomain}</h5> */}
                  {indicateurs.map((field) => (
                    <div>
                        <p style={{textAlign:"center", backgroundColor:"#0080003b",width:"100%", fontSize:"20px", color:"#258BAF"}}>Identifiant : {field.id}</p>
                      <div key={field.libelle} id="form-group">
                        <label
                          style={{ color: "#258BAF" }}
                          htmlFor={field.libelle}
                        >
                          {field.libelle}
                        </label>
                      </div>
                      <div key={field.numerateur} id="form-group">
                        <label htmlFor={field.numerateur}>
                          {field.numerateur}
                        </label>
                        <input
                          type="text"
                          id={field.numerateur}
                          name={field.numerateur}
                          value={formValues[field.numerateur]}
                          // onChange={handleInputChange}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [field.numerateur]: e.target.value,
                            })
                          }
                        />
                      </div>
                      {field.denominateur === "" ? (
                        ""
                      ) : (
                        <div key={field.denominateur} id="form-group">
                          <label htmlFor={field.denominateur}>
                            {field.denominateur}
                          </label>
                          <input
                            type="text"
                            id={field.denominateur}
                            name={field.denominateur}
                            value={formValues[field.denominateur]}
                            // onChange={handleInputChange}

                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                [field.denominateur]: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  {indicateurs.length !== 0 && ( <> 
                  <button style={{ marginRight: 20, backgroundColor: "red" }} color="red" type="reset" > Annuler </button> 
                  <button type="submit">Envoyer</button> </> )} <br />
                  {/* <button
                    style={{ marginRight: 20, backgroundColor: "red" }}
                    color="red"
                    type="reset"
                  >
                    Annuler
                  </button>
                  <button type="submit"  >Envoyer</button> <br /> */}
                </form>
              )}

              {mode_auto && (
                <form onSubmit={handleSubmit}>
                  {indicateurs.length !== 0 && ( <> 
                    <p style={{textAlign:"center"}} >Veuillez remplacer les virgule par des points ex : <span style={{backgroundColor:"#2bb977",color:"white"}} >23.44</span> et non <span style={{backgroundColor:"#c27272",color:"white"}} >23,44</span></p></> )} <br />
                  {/* <h5 style={{alignSelf:'center'}}> {subdomain}</h5> */}
                  {dataload.map((field) => (
                    <div>
                        <p style={{textAlign:"center", backgroundColor:"#0080003b",width:"100%", fontSize:"20px", color:"#258BAF"}}>Identifiant : {field.id}</p>
                      <div key={field.libelle} id="form-group">
                        <label
                          style={{ color: "#258BAF" }}
                          htmlFor={field.libelle}
                        >
                          {field.libelle}
                        </label>
                      </div>
                      <div key={field.numerateur} id="form-group">
                        <label htmlFor={field.numerateur}>
                          {field.numerateur}
                        </label>
                        <input
                          type="text"
                          id={field.numerateur}
                          name={field.numerateur}
                          value={formValues[field.numerateur]}
                          // onChange={handleInputChange}
                        />
                      </div>
                      {field.denominateur === undefined ||
                      field.denominateur === "" ? (
                        ""
                      ) : (
                        <div key={field.denominateur} id="form-group">
                          <label htmlFor={field.denominateur}>
                            {field.denominateur}
                          </label>
                          <input
                            type="text"
                            id={field.denominateur}
                            name={field.denominateur}
                            value={formValues[field.denominateur]}
                            // onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  {indicateurs.length !== 0 && ( <> 
                  <button style={{ marginRight: 20, backgroundColor: "red" }} color="red" type="reset" > Annuler </button> 
                  <button type="submit">Envoyer</button> </> )} <br />
                  {/* <button
                    style={{ marginRight: 20, backgroundColor: "red" }}
                    color="red"
                    type="reset"
                  >
                    Annuler
                  </button>
                  <button type="submit"  >Envoyer</button> */}
                </form>
              )}
            </div>
          </div>
          <input
                type="file"
                className="charger_"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <br />
              <div className="d-flex justify-content-start align-items-end gap-3">
                <button
                  className="telecharger"
                  onClick={exportToExcel}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16" style={{color:"white"}}>
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                  </svg>
                  
                </button>
                <p style={{marginTop:"0.5rem", fontSize:"0.8rem"}}>Telecharger Fichier Excel{" "}</p>
              </div>
        </div>
      </div>
    );
  };

  //----------------------------------------------Main return  ---------------------------------------------------

  return (
    <>
      <NavBarConnexion />
      <div className="w-100 d-flex justify-content-center align-items-center">
      <div className="card1">
              <Button
                className='btnIcon1'
                style={{
                   border:"1px solid white",
                  backgroundColor: isEducation ? "#316685" : "#7b9bad",
                }}
                onClick={HandleEducation}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon1"
                  fluid
                  rounded
                />
                <h5 className='titreES1'>Education</h5>
              </Button>
              <Button
                className='btnIcon1'
                style={{
                   border:"1px solid white",
                  backgroundColor: isSante ? "#316685" : "#7b9bad",
                }}
                type="button"
                onClick={HandleSante}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon1"
                  fluid
                  rounded
                />
                <h5 className='titreES1'>Sante</h5>
              </Button>
            </div>
            <div className="spaced-div donnee">
              {isEducation ? <DataEducation /> : ""}
              {isSante ? <DataSante /> : ""}
            </div>
        </div>
        <Footer />
    </>
  );
};

export default GestionData;